import React from 'react';

export default function TwoSigmaLogo() {
  return (
    <svg
      width="116"
      height="21"
      viewBox="0 0 230 42"
      xmlns="http://www.w3.org/2000/svg">
      {' '}
      <title>Two Sigma</title>
      <defs>
        <path id="a" d="M19.436 21.668V1.025H0v20.643h19.435z"></path>
      </defs>
      <g fill="none" fill-rule="evenodd">
        <path
          fill="#FFFFFF"
          d="M59.06 13.464h-7.137v-3.155h17.811v3.155H62.6V30.95h-3.54zm14.01-3.155h3.745l4.747 15.66h.06l4.483-15.66h3.301l4.454 15.66h.059l4.777-15.66h3.716L95.895 30.95H92.09l-4.335-15.127h-.059L83.361 30.95h-3.804zm41.214-.355c5.986 0 10.527 4.158 10.527 10.556 0 6.55-4.541 10.794-10.527 10.794-5.985 0-10.558-4.245-10.558-10.794 0-6.398 4.573-10.556 10.558-10.556m0 18.285c3.892 0 6.93-2.89 6.93-7.729 0-4.658-3.007-7.518-6.93-7.518-3.922 0-6.93 2.86-6.93 7.518 0 4.839 3.038 7.73 6.93 7.73m40.846-17.931h3.539V30.95h-3.54V19.41zm18.744-.355c2.832 0 5.222.885 7.313 2.33 0 0-2.026 2.374-2.128 2.311-1.56-1-3.21-1.574-5.096-1.574-4.247 0-7.048 3.068-7.048 7.433 0 4.746 2.624 7.785 7.048 7.785 1.534 0 3.067-.385 4.13-1.003v-4.897h-5.19v-2.623h8.462v9.347c-2.007 1.416-4.63 2.24-7.49 2.24-6.46 0-10.587-4.363-10.587-10.85 0-6.075 4.187-10.499 10.586-10.499m12.506.355h3.57l6.812 9.701 6.811-9.701h3.541V30.95h-3.421V15.558l-6.962 9.73-6.958-9.73V30.95h-3.392z"></path>
        <g transform="translate(210.418 9.283)">
          <path
            d="M7.639 1.025h4.158l7.64 20.643H15.63l-1.561-4.454H5.368l-1.533 4.454H0L7.639 1.025zM6.34 14.354h6.725L9.734 4.74h-.06L6.34 14.354z"
            fill="#FFFFFF"></path>
        </g>
        <path
          d="M136.826 26.498c1.861 1.007 3.618 1.68 5.887 1.68 2.715 0 4.069-1.18 4.069-2.83 0-4.66-11.616-1.594-11.616-9.466 0-3.303 2.74-5.928 7.37-5.928 2.714 0 5.443.653 7.579 1.902l-2.314 2.361c-1.68-.72-3.11-1.137-5.146-1.137-2.389 0-3.806 1.21-3.806 2.744 0 4.63 11.62 1.473 11.62 9.494 0 3.393-2.567 5.985-7.756 5.985-3.035 0-6.33-1.076-8.273-2.419l2.386-2.386z"
          fill="#FFFFFF"></path>
        <path
          fill="#009AA6"
          d="M20.625 0L0 20.63l20.625 20.628 20.63-20.628z"></path>
        <path
          d="M9.748 26.478c-.16-6.605 7.789-5.746 7.789-9.13 0-1.1-.863-2.041-2.784-2.041-1.401 0-2.743.701-3.724 1.602l-1.46-1.463c1.259-1.18 3.223-2.14 5.284-2.14 3.304 0 4.986 1.842 4.986 4.003 0 4.986-7.728 4.104-7.728 8.27h7.607v1.98h-9.95l-.02-1.081zm15.937-.5c-1.521 0-2.423-.98-2.423-2.862 0-2.404 1.602-4.566 3.525-4.566 1.5 0 2.402.981 2.402 2.883 0 2.401-1.582 4.545-3.504 4.545zm9.713-9.25h-8.444v.003c-3.437.005-6.033 2.745-6.033 6.403 0 2.905 1.881 4.666 4.544 4.666 3.464 0 6.067-2.743 6.067-6.386 0-1.182-.313-2.173-.856-2.935h2.947l1.775-1.75z"
          fill="#FFF"></path>
      </g>
    </svg>
  );
}
